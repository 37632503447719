export default {
  category: [
    {required: true, message: '请选择项目类别', trigger: 'blur'}
  ],
  area: [
    {required: true, message: '请选择面向区域', trigger: 'blur'}
  ],
  projectName: [
    {required: true, message: '请输入项目名称', trigger: 'blur'}
  ],
  dosageForm: [
    {required: true, message: '请输入剂型', trigger: 'blur'}
  ],
  indications: [
    {required: true, message: '请输入适应症', trigger: 'blur'}
  ],
  progress: [
    {required: true, message: '请输入项目进展', trigger: 'blur'}
  ],
  describe: [
    {required: true, message: '请输入项目详细描述', trigger: 'blur'}
  ],
  intention: [
    {required: true, message: '请输入合作意向', trigger: 'blur'}
  ],
  company: [
    {required: true, message: '请输入公司名称', trigger: 'blur'}
  ],
  name: [
    {required: true, message: '请输入联系人姓名', trigger: 'blur'}
  ],
  phone: [
    {required: true, message: '请输入联系人的联系电话', trigger: 'blur'}
  ]
}
