<template>
    <div class="uploadFile-content">
      <div class="upload-list">
        <ul>
          <li class="flex_ac ellipsis-1" v-for="(item, index) in fileList" :key="item.id" @click="delUploadFile(index)">
          <i class="el-icon-remove-outline"></i> {{item}}</li>
        </ul>
      </div>
      <div class="upload flex">
        <el-upload
          class="upload-demo"
          action="/api/Upload/FormUpload"
          :headers="uploadHeaders"
          :before-upload="uploadBefore"
          :on-error="uploadError"
          :on-success="uploadSuccess"
          :on-progress="uploadProgress"
          :on-remove="handleRemove"
          :show-file-list="false"
          :before-remove="beforeRemove"
          name="file"
          multiple
          >
          <el-button size="small" type="primary" class="flex_ac widthAuto"><img src="../../assets/images/icon/shangchuan.png" />点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            <p>支持doc/xlsx/zip/rar格式文件</p>
            <el-progress :percentage="uploadPercentage" :color="customColors" v-show="isUploadFile"></el-progress>
          </div>
        </el-upload>
      </div>
    </div>
</template>

<script>
export default {
  name: 'uploadFile',
  props: {
    fileListProps: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fileList: [], // 已上传文件列表
      uploadPercentage: 0, // 上传文件进度
      isUploadFile: false, // 是否开启上传文件
      customColors: [ // 上传文件进度条颜色
        {color: '#f56c6c', percentage: 20},
        {color: '#e6a23c', percentage: 40},
        {color: '#5cb87a', percentage: 60},
        {color: '#1989fa', percentage: 80},
        {color: '#6f7ad3', percentage: 100}
      ],
      uploadHeaders: { // 上传文件头部参数
        token: localStorage.getItem('token')
      }
    }
  },
  mounted() {
    this.fileList = this.fileListProps
  },
  methods: {
    handleRemove(file, fileList) { // 移除文件
      // console.log(file, fileList);
    },
    beforeRemove(file, fileList) { // 移除文件之前
      return this.$confirm(`确定移除 ${file.name} ？`)
    },
    delUploadFile(index) { // 删除上传文件
      this.fileList.splice(index, 1)
      this.$emit('uploadFileList', this.fileList)
    },
    uploadSuccess(file) { // 文件上传成功
      // console.log('file', file);
      this.isUploadFile = false
      this.fileList.push(file.data)
      // console.log('文件', this.fileList)
      this.$emit('uploadFileList', this.fileList)
    },
    uploadError(file) { // 文件上传失败
      // console.log('file', file);
      this.isUploadFile = false;
    },
    uploadProgress(file) { // 文件上传进度
      // console.log('file', file);
      this.uploadPercentage = Number(file.percent.toFixed(2))
    },
    uploadBefore(file) { // 文件状态改变
      // console.log('file', file);
      this.uploadPercentage = 0
      this.isUploadFile = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .uploadFile-content {
    min-height: 120px;
    border: 1px dashed #ddd;
    // padding-bottom: 20px;
    background: #FCFCFC;
    .upload-list {
      padding: 12px;
      ul li {
        font-size: 16px;
        margin-bottom: 10px;
        i {
          font-size: 18px;
          color: #EF2828;
          margin-right: 10px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
    .upload {
      justify-content: center;
      padding-top: 20px;
      .widthAuto{
        img{
          margin-right: 9px;
        }
      }
      /deep/ .upload-demo {
        width: 300px;
        text-align: center;
      }
      /deep/ .el-button--primary {
        color: #333;
        font-size: 14px;
        background-color: #FFDB25;
        border: 1px solid #FFDB25;
      }
      /deep/ .el-progress-bar {
        width: 90%;
      }
    }
  }
</style>
